<template>
  <div class="total flex flex-wrap-wrap height-q">
    <div
      style="width: 34%; background-color: #fff; height: 100%; margin-left: 1%"
    >
      <div
        class="width-100 padding-30"
        style="border-bottom: 2px #ececec solid; width: 100%"
      >
        <div class="width-100">
          <el-select
            class="width-100"
            v-model="courseEnrollmentvalue"
            placeholder="请选择"
          >
            <el-option label="全部课程" value=""> </el-option>
            <el-option
              v-for="item in courseEnrollmentList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>

        <div class="flex justify-content-between margin-top">
          <el-select
            style="width: 49%"
            v-model="shoolvalue"
            placeholder="请选择"
          >
            <el-option label="全部校区" value=""> </el-option>
            <el-option
              v-for="item in shoolList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <el-select
            style="width: 49%"
            v-model="Teachersvalue"
            placeholder="请选择"
          >
            <el-option label="全部教师" value=""> </el-option>
            <el-option
              v-for="item in ArrangeTeachersList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="flex justify-content-between margin-top">
          <el-select
            style="width: 49%"
            v-model="AllModesvalue"
            placeholder="请选择"
          >
            <el-option label="全部模式" value=""> </el-option>
            <el-option label="全部模式一" value="1"> </el-option>
            <el-option label="全部模二" value="2"> </el-option>
            <el-option label="全部模式三" value="3"> </el-option>
            <el-option label="全部模式四" value="4"> </el-option>
          </el-select>
          <el-select
            style="width: 49%"
            v-model="classvalue"
            placeholder="请选择"
          >
            <el-option label="开班中" value=""> </el-option>
            <el-option label="待开班" value="1"> </el-option>
            <el-option label="已完结" value="2"> </el-option>
          </el-select>
        </div>
      </div>
      <div class="width-100 padding-40-30">
        <div class="work-title-search flex flex-wrap-wrap" style="width: 500px">
          <el-select
            style="width: 150px"
            v-model="screen"
            size="medium  "
            placeholder="班级名称"
          >
            <el-option label="福星一班" value="1" />
            <el-option label="福星二班" value="2" />
            <el-option label="福星三班" value="3" />
            <el-option label="福星四班" value="4" />
          </el-select>
          <el-input
            style="width: 300px"
            placeholder="请输入班级名称或学员姓名"
            v-model="stuInput1"
            class="input-with-select"
            size="medium  "
          >
            <el-button slot="append" icon="el-icon-search" />
          </el-input>
        </div>

        <div class="margin-top">
          <el-tree
            :data="data"
            show-checkbox
            node-key="id"
            :default-expanded-keys="[2, 3]"
            :default-checked-keys="[5]"
            :props="defaultProps"
          >
          </el-tree>
        </div>
      </div>
    </div>

    <div
      style="width: 64%; background-color: #fff; height: 100%; margin-left: 1%"
    >
      <div
        class="width-100 padding-30 height-q"
        style="border-bottom: 2px #ececec solid; width: 100%"
      >
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-form-item label="发送对象">
            <span>请在左侧选择学员</span>
          </el-form-item>
          <el-form-item label="标题" prop="title">
            <el-input
              style="width: 60%"
              v-model="ruleForm.title"
              placeholder="最多可输入20个字"
            ></el-input>
          </el-form-item>
          <el-form-item label="摘要" prop="abstract">
            <el-input
              style="width: 60%"
              v-model="ruleForm.abstract"
              placeholder="最多可输入20个字"
            ></el-input>
          </el-form-item>

          <el-form-item label="模板" style="width: 100%">
            <el-button type="primary" @click="JumpUrl('/parentCenter/Template')"
              >选 择</el-button
            >
          </el-form-item>
          <el-form-item label="" style="width: 100%">
            <editor
              v-model="form.wap_desc"
              :initial-val="editor2Val"
              key-name="wap_desc"
              @change="onAdvantageEditChange"
            ></editor>
          </el-form-item>
          <el-form-item label="" class="flex justify-content-center">
            <el-button>取消</el-button>
            <el-button>预览</el-button>
            <el-button>存草稿箱</el-button>
            <el-button type="primary">发送</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>
<script>
import {
  serviceCentreList,
  courseEnrollmentList,
  fileType,
  applicationStatus,
} from "@/config/index";
import Editor from "@/components/Editor/index";
export default {
  components: { Editor },
  data() {
    return {
      
      editor2Val: "",
      classvalue: "", //开班中
      AllModesvalue: "", //全部模式
      Teachersvalue: "", //全部教师
      shoolvalue: "", //全部校区
      courseEnrollmentvalue: "", //全部课程
      applicationStatus,
      fileType,
      courseEnrollmentList, //报读课程
      serviceCentreList,
      checked: false,
      stuInput1: "",
      screen: "", //搜索
      form: {
        wap_desc: "",
        type: "",
        type1: "",
      },
      data: [
        {
          id: 1,
          label: "全校群发（仅在读学员）",
          children: [
            {
              id: 2,
              label: "未分班（0/95）",
              children: [
                {
                  id: 1,
                  label: "张雪媛",
                },
                {
                  id: 2,
                  label: "艾雪媛",
                },
                {
                  id: 3,
                  label: "周雪媛",
                },
                {
                  id: 4,
                  label: "朱雪媛(未关注)",
                  disabled: true,
                },
                {
                  id: 5,
                  label: "陈雪媛(未关注)",
                  disabled: true,
                },
              ],
            },
            {
              id: 3,
              label: "九里堤周一凡六年级培优班（2人）",
              children: [
                {
                  id: 5,
                  label: "王雪媛",
                },
                {
                  id: 6,
                  label: "杨雪媛",
                },
              ],
            },
            {
              id: 4,
              label: "九里堤周非凡六年级培优班（2人）",
              children: [
                {
                  id: 7,
                  label: "李雪雅",
                },
                {
                  id: 8,
                  label: "陈雪媛",
                },
              ],
            },
          ],
        },
      ],
      defaultProps: {
        children: "children",
        label: "label",
      },
      value: "",
      ruleForm: {
        title: "",
        abstract: "",
      },
      rules: {
        name: [
          { required: true, message: "请输入作业标题", trigger: "blur" },
          // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
        title: [
          { required: true, message: "请输入标题", trigger: "blur" },
          { max: 20, message: "最多输入20个字", trigger: "blur" },
        ],
        abstract: [{ max: 20, message: "最多输入20个字", trigger: "blur" }],
      },
    };
  },
  methods: {
         onAdvantageEditChange(type, html) {
      this.form[type] = html;
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          alert("submit!");
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    JumpUrl(url) {
      this.$router.push(url);
    },
  },
  created() { 
  },
  onLoad(options) {
    console.log(options);
  },
  resetForm(formName) {
    this.$refs[formName].resetFields();
  },
};
</script>

<style lang="scss">
.height-q {
  height: 100%;
}
.total {
  width: 100%;
  height: 100%;
}
.padding-30 {
  padding: 30px;
}
.padding-40-30 {
  padding: 40px 30px 20px 30px;
}
.width-100 {
  width: 100%;
}
</style>